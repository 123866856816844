/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import role from './role';
import {
  RoleParams,
  RolesApiProps,
  LoginRoleApi,
  UsersApiProps,
  UserApiProps,
  PermissionsApiProps,
  UserParamsProps,
  UserFilterParams,
  ProfileParamsProps,
} from './types';

// Add Tag to the list of tags to be invalidated when the list of countries is updated
const roleTag = baseApi.enhanceEndpoints({ addTagTypes: [role.tagType] });
const roleApi = roleTag.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<RolesApiProps, void>(role.getEntities()),
    getUserRoles: builder.query<RolesApiProps, void>(role.getUserRoles()),
    updateRole: builder.mutation<void, Pick<RoleParams, 'id'> & Partial<RoleParams>>(
      role.updateEntity(),
    ),
    loginRole: builder.mutation<LoginRoleApi, { id: number }>(role.login()),
    switchRole: builder.mutation<LoginRoleApi, { id: number }>(role.switch()),
    getUsers: builder.query<UsersApiProps, number>(role.getEntitiesWithId('sites')),
    deleteUser: builder.mutation<{ success: boolean; id: number }, number>(
      role.deleteEntity(),
    ),
    addUser: builder.mutation<UserApiProps, Partial<UserApiProps>>(
      role.addEntity('sites'),
    ),
    getUserRole: builder.query<UserApiProps, number>(role.getEntity()),
    updateUserRole: builder.mutation<
      void,
      Pick<UserApiProps, 'id'> & Partial<UserApiProps>
    >(role.updateEntity()),
    getPermissions: builder.query<PermissionsApiProps, number>(
      role.profilePermissions(),
    ),
    addPermission: builder.mutation<void, UserParamsProps>(role.addPermission()),
    updatePermission: builder.mutation<void, ProfileParamsProps>(
      role.updatePermission(),
    ),
    removePermission: builder.mutation<void, number>(role.removePermission()),
    getContractorUsers: builder.query<UsersApiProps, UserFilterParams>(
      role.getContractorRoles(),
    ),
    getSiteUsers: builder.query<UsersApiProps, UserFilterParams>(
      role.getSiteRoles(),
    ),
    loginRoleWithToken: builder.mutation<
      LoginRoleApi,
      {
        token: string;
      }
    >(role.loginRoleWithToken()),
    impersonate: builder.mutation<LoginRoleApi, { id: number }>(role.impersonate()),

  }),
  overrideExisting: false,
});

export const {
  useUpdateRoleMutation,
  useGetRolesQuery,
  useLoginRoleMutation,
  useSwitchRoleMutation,
  useGetUserRolesQuery,
  useGetUsersQuery,
  useDeleteUserMutation,
  useAddUserMutation,
  useGetUserRoleQuery,
  useUpdateUserRoleMutation,
  useGetPermissionsQuery,
  useAddPermissionMutation,
  useUpdatePermissionMutation,
  useRemovePermissionMutation,
  useGetContractorUsersQuery,
  useGetSiteUsersQuery,
  useLoginRoleWithTokenMutation,
  useImpersonateMutation,
} = roleApi;
