import React, { useEffect } from 'react';
import { useSwitchProfile } from '@hooks/useSwitchProfile';
import {
  Row, Col, useParams, Spin, Space,
} from '@lib/index';

const SwitchRole = () => {
  const params = useParams();
  const { id, type } = params;
  const { handleSwitchProfile, handleImpersonate, handleRemoveImpersonation } = useSwitchProfile();

  useEffect(() => {
    if (type === 'switching') {
      (async (roleId: number) => {
        await handleSwitchProfile(roleId);
      })(Number(id));
    }
    if (type === 'impersonating') {
      (async (userId: number) => {
        await handleImpersonate(userId);
      })(Number(id));
    }
    if (type === 'remove-impersonation') {
      (async () => {
        await handleRemoveImpersonation();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row align="middle" justify="center" className="h-screen">
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="flex justify-center">
        <Space align="center">
          <Spin size="large" tip={type === 'switching' ? 'Switching Role' : 'Impersonating User'} />
        </Space>
      </Col>
    </Row>
  );
};

export default SwitchRole;
