/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CamelToSnakeCaseNested, handleResponse, objectToFlattenArray } from '@helpers/index';
import {
  AccountStateProps,
  LoginUserProps,
  LoginResponseProps,
  MyKnownErrorProps,
  LoginRoleProps,
} from './types';
import { authenticationService } from '../../_services';

const initialState: AccountStateProps = {
  currentUser: {
    role: '',
    email: '',
    userId: null,
    roleId: null,
    token: '',
    contractorId: null,
    metaContractorId: null,
    siteId: null,
    parentSiteId: null,
    kitchen: '',
    sectionId: null,
    firstName: '',
    lastName: '',
    roleLabel: '',
    lang: 'es',
    passwordChange: false,
    permissions: [],
  },
  permissionsErrorMessage: '',
  permissionsFetching: false,
  permissionsSuccess: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  impersonatingUser: {
    email: '',
    role: '',
    userId: null,
    roleId: null,
    kitchen: '',
    roleLabel: '',
    firstName: '',
    lastName: '',
  },
};

export const loginUser = createAsyncThunk(
  'account/login',
  async ({ email, password, recaptcha }: LoginUserProps, thunkApi) => {
    try {
      const response = await authenticationService.logIn(email, password, recaptcha);
      const token = response.headers.get('authorization')?.substring(7);
      const data: LoginResponseProps = await handleResponse(response);
      if (response.status !== 200) {
        return thunkApi.rejectWithValue(
          (await response.json()) as MyKnownErrorProps,
        );
      }
      return { token, data };
    } catch (err) {
      console.error('Error', err);
      return thunkApi.rejectWithValue(err);
    }
  },
);

export const getRolePermissions = createAsyncThunk(
  'account/permissions',
  async (roleToken: string, thunkApi) => {
    try {
      const response = await authenticationService.getRolePermissions(roleToken as string);
      const data: LoginResponseProps = await handleResponse(response);
      if (response.status !== 200) {
        return thunkApi.rejectWithValue(
          (await response.json()) as MyKnownErrorProps,
        );
      }
      return { data };
    } catch (err) {
      console.error('Error', err);
      return thunkApi.rejectWithValue(err);
    }
  },
);

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearState: (state: {
      isSuccess: boolean;
      isError: boolean;
      isFetching: boolean;
    }) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
    assignRole: (state: {
      isSuccess: boolean;
      isError: boolean;
      isFetching: boolean;
      currentUser: {
        email: string;
        token: string | null;
        userId: number | null;
        role: string;
        roleId: number | null;
        siteId: number | null;
        parentSiteId: number | null;
        contractorId: number | null;
        metaContractorId: number | null;
        kitchen?: string | null;
        sectionId: number | null;
        firstName: string | null;
        lastName: string | null;
        roleLabel: string | null;
        lang: string;
      };
    }, action: PayloadAction<CamelToSnakeCaseNested<LoginRoleProps>>) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.currentUser.role = action.payload.role_user;
      state.currentUser.roleId = action.payload.role_id;
      state.currentUser.siteId = action.payload.site_id;
      state.currentUser.contractorId = action.payload.contractor_id;
      state.currentUser.metaContractorId = action.payload.meta_contractor_id;
      state.currentUser.kitchen = action.payload.token;
      state.currentUser.email = action.payload.email;
      state.currentUser.userId = action.payload.user_id;
      state.currentUser.sectionId = action.payload.section_id;
      state.currentUser.token = null;
      state.currentUser.firstName = action.payload.first_name;
      state.currentUser.lastName = action.payload.last_name;
      state.currentUser.roleLabel = action.payload.role_label;
      state.currentUser.lang = 'es';
      state.currentUser.parentSiteId = action.payload.parent_site_id;
    },
    logOut: (state: {
      currentUser: {
        email: string;
        token: string;
        userId: number | null;
        role: string;
        roleId: number | null;
        siteId: number | null;
        contractorId: number | null;
        metaContractorId: number | null;
        kitchen?: string | null;
        sectionId: number | null;
        roleLabel: string | null;
        lang: string;
        firstName: string | null;
        lastName: string | null;
        passwordChange: boolean;
        parentSiteId: number | null;
      };
      impersonatingUser: {
        email: string;
        userId: number | null;
        role: string;
        roleId: number | null;
        kitchen: string | null;
      }
    }) => {
      state.currentUser.email = '';
      state.currentUser.token = '';
      state.currentUser.userId = null;
      state.currentUser.role = '';
      state.currentUser.roleId = null;
      state.currentUser.contractorId = null;
      state.currentUser.metaContractorId = null;
      state.currentUser.siteId = null;
      state.currentUser.kitchen = null;
      state.currentUser.sectionId = null;
      state.currentUser.roleLabel = '';
      state.currentUser.firstName = '';
      state.currentUser.lastName = '';
      state.currentUser.passwordChange = false;
      state.currentUser.parentSiteId = null;
      if (state?.impersonatingUser?.email) {
        state.impersonatingUser.email = '';
        state.impersonatingUser.userId = null;
        state.impersonatingUser.role = '';
        state.impersonatingUser.roleId = null;
        state.impersonatingUser.kitchen = null;
      }
      authenticationService.logOut();
    },
    assignLang: (
      state: {
        currentUser: {
          lang: string;
        };
      },
      action: PayloadAction<string>,
    ) => {
      state.currentUser.lang = action.payload;
    },
    assignContractor: (
      state: {
        currentUser: {
          contractorId: number | null;
        };
      },
      action: PayloadAction<number>,
    ) => {
      state.currentUser.contractorId = action.payload;
    },
    assignPermissions: (
      state: {
        currentUser: {
          permissions: string[];
        };
      },
      action: PayloadAction<any>,
    ) => {
      state.currentUser.permissions = objectToFlattenArray(action.payload);
    },
    impersonateUser: (state: {
      impersonatingUser: {
        email: string;
        userId: number | null;
        role: string;
        roleId: number | null;
        kitchen: string | null;
        roleLabel: string | null;
        firstName: string | null;
        lastName: string | null;
      }

    }, action: PayloadAction<AccountStateProps['currentUser']>) => {
      state.impersonatingUser.email = action.payload.email;
      state.impersonatingUser.userId = action.payload.userId;
      state.impersonatingUser.role = action.payload.role;
      state.impersonatingUser.roleId = action.payload.roleId;
      state.impersonatingUser.kitchen = action.payload.kitchen;
      state.impersonatingUser.roleLabel = action.payload.roleLabel;
      state.impersonatingUser.firstName = action.payload.firstName;
      state.impersonatingUser.lastName = action.payload.lastName;
    },
    removeImpersonation: (state: {
      currentUser: {
        email: string;
        userId: number | null;
        role: string;
        roleId: number | null;
        kitchen: string | null;
        roleLabel: string | null;
        firstName: string | null;
        lastName: string | null;
      },
      impersonatingUser: {
        email: string;
        userId: number | null;
        role: string;
        roleId: number | null;
        kitchen: string | null;
        roleLabel: string | null;
        firstName: string | null;
        lastName: string | null;
      }
     }, action: PayloadAction<Pick<AccountStateProps['currentUser'], 'email' | 'userId' | 'role'
      | 'roleId' | 'kitchen' | 'firstName' | 'lastName' | 'roleLabel' >>) => {
      state.currentUser.email = action.payload.email;
      state.currentUser.userId = action.payload.userId;
      state.currentUser.role = action.payload.role;
      state.currentUser.roleId = action.payload.roleId;
      state.currentUser.kitchen = action.payload.kitchen;
      state.currentUser.roleLabel = action.payload.roleLabel;
      state.currentUser.firstName = action.payload.firstName;
      state.currentUser.lastName = action.payload.lastName;
      state.impersonatingUser.email = '';
      state.impersonatingUser.userId = null;
      state.impersonatingUser.role = '';
      state.impersonatingUser.roleId = null;
      state.impersonatingUser.kitchen = null;
      state.impersonatingUser.roleLabel = '';
      state.impersonatingUser.firstName = '';
      state.impersonatingUser.lastName = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, { payload }: { payload: any }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.currentUser.token = payload.token;
      state.currentUser.passwordChange = payload.data.request_password_change;
    });
    builder.addCase(loginUser.rejected, (state, { payload }: { payload: any }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(loginUser.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getRolePermissions.fulfilled, (state, { payload }: { payload: any }) => {
      state.permissionsFetching = false;
      state.permissionsSuccess = true;
      state.currentUser.permissions = objectToFlattenArray(payload.data);
    });
    builder.addCase(getRolePermissions.rejected, (state, { payload }: { payload: any }) => {
      state.permissionsFetching = false;
      state.permissionsErrorMessage = payload;
    });
    builder.addCase(getRolePermissions.pending, (state) => {
      state.permissionsFetching = true;
    });
  },
});

export const {
  clearState,
  logOut,
  assignRole,
  assignLang,
  assignContractor,
  assignPermissions,
  impersonateUser,
  removeImpersonation,
} = accountSlice.actions;

export default accountSlice.reducer;
