/* eslint-disable import/prefer-default-export */
import { PERMISSIONS, ROLES } from '@config/index';
import { useAppSelector } from './redux.hooks';

export const useGetPermissions = () => {
  const { currentUser } = useAppSelector((state) => state.account);
  const { role } = currentUser;
  const permissions = role !== ROLES.admin
    ? [...PERMISSIONS[role], ...currentUser.permissions] : PERMISSIONS[role];
  return permissions;
};
