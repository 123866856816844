/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState, useEffect } from 'react';
import { eventBus } from '@helpers/index';
import humps from 'lodash-humps-ts';
import { roleService } from '@services/index';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  assignRole,
  clearState,
  getRolePermissions,
  impersonateUser,
  logOut,
  removeImpersonation,
} from '@features/account/AccountSlice';
import { useAppSelector, useAppDispatch } from '@hooks/index';
import { ROLES } from '@config/index';

export type Role = {
  id: number;
  profiles: string[];
  dutyName?: string;
  enableJobEmail: boolean;
  dailyNotificationTime?: string;
  category: string;
  roleLabel: string;
};

export const useSwitchProfile = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [skip, setSkip] = useState(true);
  const { currentUser, isError, impersonatingUser } = useAppSelector((state) => state.account);
  const {
    useGetUserRolesQuery, useLoginRoleMutation, useImpersonateMutation, useSwitchRoleMutation,
  } = roleService;
  const { data } = useGetUserRolesQuery(undefined, { skip });
  const [loginRole] = useLoginRoleMutation();
  const [switchRole] = useSwitchRoleMutation();
  const roles = humps(data) as Role[];
  const { roleId, token, passwordChange } = currentUser;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAdmin = currentUser.role === ROLES.admin;
  const [impersonate] = useImpersonateMutation();

  const handleSelectProfile = useCallback(async () => {
    try {
      if (id === null) {
        message.error('Please select a role!', 2);
        return;
      }
      const response = await loginRole({ id }).unwrap();

      if (response !== undefined) {
        await dispatch(assignRole(response));
        if (!isAdmin) await dispatch(getRolePermissions(response.token as string));
        setIsModalVisible(false);
      }
    } catch (e) {
      message.error('Role Selection was Unsuccessful', 2);
      dispatch(clearState());
    }
  }, [dispatch, id, loginRole]);

  const handleSwitchProfile = useCallback(
    async (switchedRoleId: number) => {
      try {
        if (switchedRoleId === null) {
          message.error('Please select a role!', 2);
          return;
        }
        const response = await switchRole({ id: switchedRoleId }).unwrap();
        if (response !== undefined) {
          if (!isAdmin) await dispatch(getRolePermissions(response.token as string)).unwrap();
          dispatch(assignRole(response));
          navigate('/dashboard');
        }
      } catch (e) {
        message.error('Role Selection was Unsuccessful', 2);
        dispatch(clearState());
      }
    },
    [dispatch, switchRole],
  );

  const handleCancel = () => {
    eventBus.dispatch('logout', {});
    dispatch(logOut());
    setIsModalVisible(false);
    setSkip((prevState) => !prevState);
    navigate('/login');
  };

  const showModal = () => setIsModalVisible(true);
  useEffect(() => {
    if (token && roleId === null && passwordChange === false) {
      setSkip((prevState) => !prevState);
      showModal();
    }
  }, [roleId, token]);

  const handleImpersonate = useCallback(async (userId: number) => {
    try {
      if (currentUser.role !== ROLES.admin) {
        message.error('Something went wrong!', 2);
        return;
      }
      dispatch(impersonateUser(currentUser));
      const response = await impersonate({ id: userId }).unwrap();
      if (response !== undefined) {
        dispatch(assignRole(response));
        await dispatch(getRolePermissions(response.token as string));
        navigate('/dashboard');
        return;
      }
    } catch (error) {
      dispatch(clearState());
      message.error('Role Selection was Unsuccessful', 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveImpersonation = useCallback(async () => {
    try {
      dispatch(removeImpersonation(impersonatingUser));
      navigate('/dashboard');
      return;
    } catch (error) {
      dispatch(clearState());
      message.error('Role Selection was Unsuccessful', 2);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    roles,
    handleCancel,
    showModal,
    handleSelectProfile,
    setId,
    isModalVisible,
    id,
    roleId,
    handleSwitchProfile,
    isError,
    currentUser,
    setSkip,
    handleImpersonate,
    handleRemoveImpersonation,
  };
};
