/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import baseApi from '@features/base/BaseSlice';
import document from './document';
import {
  DocumentApiProps,
  DocumentsApiProps,
  DocumentFileApiProps,
  DocumentParamsProps,
  DocumentParams,
  DocumentsHistoryApiProps,
  UploadDocumentParams,
  MultipleUploadParams,
  MultipleDocumentApiProps,
  DocumentAlertParams,
  ContractorVehicleDocumentParams,
  ContractorVehicleDocumentApiProps,
} from './types';

const documentTag = baseApi.enhanceEndpoints({ addTagTypes: [document.tagType] });
const documentApi = documentTag.injectEndpoints({
  endpoints: (builder) => ({
    deleteDocument:
      builder.mutation<void, number>(document.delete()),
    addDocument: builder.mutation<DocumentApiProps, DocumentParams>(
      document.createDocument(),
    ),
    addContractorVehicleDocument: builder.mutation<
      ContractorVehicleDocumentApiProps,
      ContractorVehicleDocumentParams
    >(document.createContractorVehicleDocument()),
    addJobVehicleDocument: builder.mutation<
      ContractorVehicleDocumentApiProps,
      ContractorVehicleDocumentParams
    >(document.createJobVehicleDocument()),
    getDocuments:
      builder.query<DocumentsApiProps, DocumentParamsProps>(document.getDocumentations()),
    getDocumentHistory:
      builder.query<DocumentsHistoryApiProps, DocumentParamsProps>(
        document.getDocumentHistory(),
      ),
    uploadDocument:
      builder.mutation<DocumentFileApiProps, UploadDocumentParams>(document.uploadDocument()),
    uploadJobDocument:
      builder.mutation<DocumentFileApiProps, UploadDocumentParams>(document.uploadJobDocument()),
    multipleUploadDocument:
      builder.mutation<MultipleDocumentApiProps, MultipleUploadParams>(
        document.multipleUpload(),
      ),
    getDocument:
      builder.query<DocumentApiProps, string>(document.getDocument()),
    updateDocument: builder.mutation<void, Pick<DocumentAlertParams, 'id'> & Partial<DocumentAlertParams>>(
      document.updateDocument(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useDeleteDocumentMutation,
  useAddDocumentMutation,
  useAddContractorVehicleDocumentMutation,
  useAddJobVehicleDocumentMutation,
  useGetDocumentsQuery,
  useGetDocumentQuery,
  useGetDocumentHistoryQuery,
  useUploadDocumentMutation,
  useUploadJobDocumentMutation,
  useMultipleUploadDocumentMutation,
  useUpdateDocumentMutation,
} = documentApi;
