import BaseService from '@services/base';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { RevisionSituationsApiProps, RevisionCommentsApiProps, RevisionDiffApiProps } from './types';

class Revision extends BaseService {
  getRevisionSituation() {
    return {
      query: (id: number) => ({
        url: `${this.url}/${id}/situations`,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: RevisionSituationsApiProps) => [{ type: this.tagType }],
    };
  }

  getRevisionComment() {
    return {
      query: (id: number) => ({
        url: `${this.url}/${id}/comments`,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: RevisionCommentsApiProps) => [{ type: this.tagType }],
    };
  }

  updateJob() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ id, jobId, ...patch }: { id: number, jobId: number }) => ({
        url: `${this.url}/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (
        result: void | undefined,
        error: FetchBaseQueryError | undefined,
        { jobId }: { jobId: number },
      ) => [{ type: 'Job', id: jobId }],
    };
  }

  sendJobDraft() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ id, jobId, ...body }: { id: number, jobId: number }) => ({
        url: `${this.url}/${id}/states`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (
        result: void | undefined,
        error: FetchBaseQueryError | undefined,
        { jobId }: { jobId: number },
      ) => [{ type: 'Job', id: jobId }],
    };
  }

  deleteRevision() {
    return {
      query: ({ id }: { id: number, jobId: number }) => ({
        url: `${this.url}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (
        result: void | undefined,
        error: FetchBaseQueryError | undefined,
        { jobId }: { jobId: number },
      ) => [{ type: 'Job', id: jobId }],
    };
  }

  updateRevision() {
    return {
      query: ({ id, ...params }: RevisionDiffApiProps) => ({
        url: `/revisions/${id}`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: [{ type: this.tagType }, { type: 'Job' }],
    };
  }

  updateGeneralInfoJob() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ id, jobId, ...patch }: { id: number, jobId: number }) => ({
        url: `${this.url}/${id}`,
        method: 'PATCH',
        body: patch,
      }),
    };
  }
}

export default new Revision('revisions', 'Revision');
