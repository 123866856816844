/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */

import baseApi from '@features/base/BaseSlice';
import {
  JobsApiProps,
  JobParamsProps,
  DocStatusApiProps,
  ContractStatusApiProps,
  JobSituationsApiProps,
  JobApiProps,
  JobReviewParamsProps,
  JobGroupParamsProps,
  JobShiftParamsProps,
  JobPlacementParamsProps,
  JobContractApiProps,
  JobContract,
  JobOutComeApiProps,
  JobOutComeProps,
  JobEvaluationProps,
  ContractorVehicleApiParams,
  ContractorVehicleApiProps,
} from './types';
import job from './job';

// Add Tag to the list of tags to be invalidated when the list of jobs is updated
const jobTag = baseApi.enhanceEndpoints({ addTagTypes: [job.tagType] });
const jobAPi = jobTag.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query<JobsApiProps, JobParamsProps>(job.getJobs()),
    getDocStatus: builder.query<DocStatusApiProps, number>(job.getDocStatus()),
    getContractStatus: builder.query<ContractStatusApiProps, number>(
      job.getContractStatus(),
    ),
    getJobSituation: builder.query<JobSituationsApiProps, number>(
      job.getJobSituation(),
    ),
    getJob: builder.query<JobApiProps, number>(job.getEntity()),
    addReview: builder.mutation<void, JobReviewParamsProps>(job.addReview()),
    addJobShift: builder.mutation<void, JobShiftParamsProps>(job.addJobShift()),
    updateJobShift: builder.mutation<void, JobShiftParamsProps>(job.updateJobShift('job-shifts')),
    addJobPlacement: builder.mutation<void, JobPlacementParamsProps>(job.addJobPlacement()),
    addJobGroup: builder.mutation<{ id: number}, JobGroupParamsProps>(job.addJobGroup()),
    deleteJobShift: builder.mutation<{ success: boolean; id: number }, {id: number; jobId: number}>(
      job.deleteJobEntity('job-shifts'),
    ),
    deleteJobPlacement: builder.mutation<{ success: boolean;
       id: number }, {id: number; jobId: number}>(
         job.deleteJobEntity('job-placements'),
       ),
    deleteJobGroup: builder.mutation<{ success: boolean;
        id: number }, {id: number; jobId: number}>(
          job.deleteJobEntity('job-groups'),
        ),
    deleteJob: builder.mutation<{ success: boolean; id: number }, number>(job.deleteEntity()),
    // eslint-disable-next-line max-len
    updateJobGroupEmails: builder.mutation<void, { id: number, groupsReportmails: string[] }>(job.updateEntity()),
    getJobContracts: builder.query<JobContractApiProps, number>(
      job.getJobContracts(),
    ),
    addContract: builder.mutation<
      JobContract,
      Partial<JobContract>>(job.createJobContract()),
    updateJobOutCome: builder.mutation<JobOutComeApiProps, Pick<JobOutComeProps, 'jobId'> & Partial<JobOutComeProps>>(job.updateJobOutCome()),
    updateJobEvaluation: builder.mutation<JobsApiProps, Pick<JobEvaluationProps, 'jobId'> & Partial<JobEvaluationProps>>(job.updateJobEvaluation()),
    addVehicle: builder.mutation<
      ContractorVehicleApiProps,
      ContractorVehicleApiParams>(job.createJobVehicle()),
    deleteJobVehicle: builder.mutation<{
      success: boolean;
      id: number
    }, number>(job.deleteJobVehicle()),
    getJobsCount: builder.query<{ count: number }, JobParamsProps>(job.getJobsCount()),
  }),
  overrideExisting: false,
});

export const {
  useGetJobsQuery,
  useGetDocStatusQuery,
  useGetContractStatusQuery,
  useGetJobSituationQuery,
  useGetJobQuery,
  useAddReviewMutation,
  useAddJobPlacementMutation,
  useDeleteJobPlacementMutation,
  useAddJobShiftMutation,
  useUpdateJobShiftMutation,
  useDeleteJobShiftMutation,
  useAddJobGroupMutation,
  useDeleteJobGroupMutation,
  useDeleteJobMutation,
  useUpdateJobGroupEmailsMutation,
  useGetJobContractsQuery,
  useAddContractMutation,
  useUpdateJobOutComeMutation,
  useUpdateJobEvaluationMutation,
  useAddVehicleMutation,
  useDeleteJobVehicleMutation,
  useGetJobsCountQuery,
} = jobAPi;
