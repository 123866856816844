/* eslint-disable max-len */
import {
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import BaseService from '../base';
import {
  UserFilterParams,
  LoginRoleApi,
  PermissionsApiProps,
  UserParamsProps,
  ProfileParamsProps,
} from './types';

class Role extends BaseService {
  update() {
    return {
      query: ({ id, ...patch }: { id: number }) => ({
        url: `roles/${id}/email-notification`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (
        result: void | undefined,
        error: FetchBaseQueryError | undefined,
        { id }: { id: number },
      ) => [{ type: this.tagType, id }, { type: 'User' }],
    };
  }

  login() {
    return {
      query: (body: { id: number }) => {
        const { id } = body;
        return {
          url: '/kitchen/sign_in',
          method: 'POST',
          body: { role_id: id },
        };
      },
      transformResponse(
        apiResponse: Omit<LoginRoleApi, 'token'>,
        meta: FetchBaseQueryMeta | undefined,
      ) {
        return {
          ...apiResponse,
          token: meta?.response?.headers.get('authorization')?.substring(7),
        };
      },
      invalidatesTags: [
        { type: this.tagType, id: 'LIST' },
        { type: 'Job' },
        { type: 'User' },
        { type: 'Shift' },
        { type: 'Account' },
        { type: 'Worker' },
        { type: 'Access' },
        { type: 'Announcement' },
      ],
    };
  }

  switch() {
    return {
      query: (body: { id: number }) => {
        const { id } = body;
        return {
          url: '/batcave/sign_in',
          method: 'POST',
          body: { role_id: id },
        };
      },
      transformResponse(
        apiResponse: Omit<LoginRoleApi, 'token'>,
        meta: FetchBaseQueryMeta | undefined,
      ) {
        return {
          ...apiResponse,
          token: meta?.response?.headers.get('authorization')?.substring(7),
        };
      },
      invalidatesTags: [
        { type: this.tagType, id: 'LIST' },
        { type: 'Job' },
        { type: 'User' },
        { type: 'Shift' },
        { type: 'Account' },
        { type: 'Worker' },
        { type: 'Access' },
        { type: 'Announcement' },
      ],
    };
  }

  getUserRoles() {
    return {
      query: () => `/user_${this.url}`,
    };
  }

  profilePermissions() {
    return {
      query: (id: number) => `${this.url}/${id}/profile-templates`,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: PermissionsApiProps) => [{ type: 'ProfileTemplate' }],
    };
  }

  // eslint-disable-next-line class-methods-use-this
  addPermission() {
    return {
      query: ({ id, ...body }: UserParamsProps) => ({
        url: `/roles/${id}/profiles`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'ProfileTemplate' }],
    };
  }

  // eslint-disable-next-line class-methods-use-this
  removePermission() {
    return {
      query: (id: number) => ({
        url: `profiles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'ProfileTemplate' }],
    };
  }

  // eslint-disable-next-line class-methods-use-this
  updatePermission() {
    return {
      query: ({ id, ...body }: ProfileParamsProps) => ({
        url: `profiles/${id}`,
        method: 'PATCH',
        body: {
          ...body,
          id,
        },
      }),
      invalidatesTags: [{ type: 'ProfileTemplate' }],
    };
  }

  getContractorRoles() {
    return {
      query: ({ id, ...params }: UserFilterParams) => ({
        url: `/contractors/${id}/roles`,
        params,
      }),
      providesTags: () => [{ type: this.tagType, id: 'LIST' }],
    };
  }

  getSiteRoles() {
    return {
      query: ({ id, ...params }: UserFilterParams) => ({
        url: `/sites/${id}/roles`,
        params,
      }),
      providesTags: () => [{ type: this.tagType, id: 'LIST' }],
    };
  }

  loginRoleWithToken() {
    return {
      query: (body: { token: string }) => {
        const { token } = body;
        return {
          url: '/office/sign_in',
          method: 'POST',
          body: { auth_token: token },
        };
      },
      transformResponse(
        apiResponse: Omit<LoginRoleApi, 'token'>,
        meta: FetchBaseQueryMeta | undefined,
      ) {
        return {
          ...apiResponse,
          token: meta?.response?.headers.get('authorization')?.substring(7),
        };
      },
      invalidatesTags: [
        { type: this.tagType, id: 'LIST' },
        { type: 'Job' },
        { type: 'User' },
        { type: 'Shift' },
        { type: 'Account' },
        { type: 'Worker' },
        { type: 'Access' },
        { type: 'Announcement' },
      ],
    };
  }

  impersonate() {
    return {
      query: (body: { id: number }) => {
        const { id } = body;
        return {
          url: '/alter/sign_in',
          method: 'POST',
          body: { user_id: id },
        };
      },
      transformResponse(
        apiResponse: Omit<LoginRoleApi, 'token'>,
        meta: FetchBaseQueryMeta | undefined,
      ) {
        return {
          ...apiResponse,
          token: meta?.response?.headers.get('authorization')?.substring(7),
        };
      },
      invalidatesTags: [
        { type: this.tagType, id: 'LIST' },
        { type: 'Job' },
        { type: 'User' },
        { type: 'Shift' },
        { type: 'Account' },
        { type: 'Worker' },
        { type: 'Access' },
        { type: 'Announcement' },
      ],
    };
  }
}

export default new Role('roles', 'Role');
