/* eslint-disable class-methods-use-this */
import { providesList } from '@helpers/index';
import BaseService from '../base';
import { UserApiProps, UsersApiProps } from './types';

class User extends BaseService {
  updatePassword() {
    return {
      query: <T>(body: T) => ({
        url: '/auth/password',
        method: 'PATCH',
        body,
      }),
    };
  }

  updateUserPassword() {
    return {
      query: <T>(body: T) => ({
        url: '/auth/user-password',
        method: 'PATCH',
        body,
      }),
    };
  }

  getUsers() {
    return {
      query: (params: UserApiProps) => ({
        url: '/users',
        params,
      }),
      providesTags: (result: UsersApiProps | undefined) => (
        providesList(result?.users, this.tagType)
      ),
    };
  }
}

export default new User('users', 'User');
